import { ColumnCode, Config, SurveyTemplate } from '@g17eco/types/custom-report';

export enum CustomReportTemplateType {
  Default = 'default',
  Transposed = 'transposed',
  Tabular = 'tabular',
}

export interface Form extends Required<Pick<Config, 'columns' | 'ordering' | 'templateType'>> {
  _id?: string;
  name: string;
  description?: string;
  surveyIds: SurveyTemplate['ids'];
  metricFilters: Omit<SurveyTemplate, 'ids'>;
}

export type OnChangeForm = (form: Partial<Form>) => void;

export interface Option<HasCustom extends boolean = false> {
  label: string;
  value: HasCustom extends true ? ColumnCode | 'custom' : ColumnCode;
}
