import UniversalTracker from '@models/UniversalTracker';
import { SurveyModelMinimalUtrv } from '@models/surveyData';
import { DisaggregationResponse } from '@api/disaggregation';
import { ColumnDef } from '@tanstack/react-table';
import { ValueAggregation, valueAggregationMap } from '@g17eco/types/disaggregation';
import { MinimalUtrv, UniversalTrackerValueView } from '@features/universal-tracker-value';
import { Table } from '@g17eco/molecules';
import { Button } from 'reactstrap';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { useHistory } from 'react-router-dom';
import { DATE, formatDateUTC } from '@utils/date';
import { getPeriodName } from '@utils/universalTracker';
import { useAppSelector } from '../../../../reducers';
import { getInitiativeTree } from '@selectors/initiativeTree';
import { getBranchInitiativeNameText } from '@routes/initiative-structure/utils';
import './styles.scss';
import { naturalSort } from '@utils/index';
import { getSupportedValueText, sortBySubsidiary } from './utils';
import { ExtendedHistoryData } from './types';
import { useMemo } from 'react';

interface Props {
  utr?: UniversalTracker;
  utrv?: SurveyModelMinimalUtrv;
  data?: DisaggregationResponse;
}

export const AggregatedBreakdownDetail = (props: Props) => {
  const { utr, utrv, data } = props;
  const history = useHistory();
  const tree = useAppSelector(getInitiativeTree);

  const disaggregatedData: ExtendedHistoryData[] = useMemo(() => {
    if (!utr || !utrv || !data) {
      return [];
    }
    return data.disaggregatedData
      .map((rowData, index) => {
        let displayAnswer = '';

        if (index !== 0 && data.valueAggregation === ValueAggregation.LatestAggregator) {
          displayAnswer = 'Ignore';
        } else {
          displayAnswer = getSupportedValueText(rowData, utr);
        }

        return {
          ...rowData,
          displaySubsidiary: getBranchInitiativeNameText({
            initiativeTree: tree,
            initiativeId: rowData.initiativeId,
            showInitiativeId: true,
          }),
          displayDate: formatDateUTC(rowData.date ?? '', DATE.MONTH_YEAR_SHORT),
          displayPeriod: getPeriodName(rowData.period),
          displayAnswer,
        };
      })
      .sort(sortBySubsidiary);
  }, [data, tree, utr, utrv]);

  if (!utr || !utrv || !data) {
    return null;
  }

  const columns: ColumnDef<ExtendedHistoryData>[] = [
    {
      header: 'Subsidiary',
      accessorFn: (row) => row.displaySubsidiary,
      sortingFn: (a, b) => sortBySubsidiary(a.original, b.original),
    },
    {
      header: 'Date',
      accessorFn: (row) => row.displayDate,
    },
    {
      header: 'Period',
      accessorFn: (row) => row.displayPeriod,
    },
    {
      header: 'Answer',
      accessorFn: (row) => row.displayAnswer,
      cell: ({ row }) => {
        if (row.index !== 0 && data.valueAggregation === ValueAggregation.LatestAggregator) {
          return <div className='text-danger'>Ignore</div>;
        }
        const minimalUtrv: MinimalUtrv = {
          _id: row.original.utrvId,
          status: utrv.status,
          value: row.original.value,
          valueData: row.original.valueData,
        };
        return (
          <div className='aggregated-breakdown__table-answer-col text-truncate'>
            <UniversalTrackerValueView utr={utr} utrv={minimalUtrv} valueType={utr.getValueType()} />
          </div>
        );
      },
      sortingFn: (a, b) =>
        naturalSort(a.original.displayAnswer, b.original.displayAnswer),
    },
    {
      id: 'view',
      header: '',
      cell: ({ row }) => {
        if (!row.original.surveyId) {
          return null;
        }
        const surveyPath = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
          initiativeId: row.original.initiativeId,
          surveyId: row.original.surveyId,
          page: 'question',
        });
        return (
          <Button color='link' onClick={() => history.push({ pathname: `${surveyPath}/${row.original.utrvId}` })}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <p>Aggregation calculation: {valueAggregationMap[data.valueAggregation]}</p>
      <Table responsive={true} columns={columns} data={disaggregatedData} className='aggregated-breakdown__table' />
    </>
  );
};
