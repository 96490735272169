import { Button } from 'reactstrap';
import { InsightDashboard, InsightDashboardSettingKeys } from '@g17eco/types/insight-custom-dashboard';
import { useToggle } from '@hooks/useToggle';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { DashboardSettingsSidebar } from './DashboardSettingsSidebar';

interface Props {
  dashboard: Pick<InsightDashboard, 'title' | 'filters' | 'type' | 'items' | 'initiativeId'>;
  handleSave: (dashboard: Partial<InsightDashboard>, keepEditing?: boolean) => void;
  handleDelete?: () => void;
  hideOptions?: InsightDashboardSettingKeys[];
  availablePeriods: DataPeriods[];
}

export const DashboardSettings = ({ dashboard, hideOptions = [], availablePeriods, handleSave, handleDelete = () => {} }: Props) => {
  const [openSettingsSidebar, toggleSettingsSidebar, setOpenSettingsSidebar] = useToggle(false);

  return (
    <>
      <Button
        tooltip='Select the data being pulled'
        color='secondary'
        className='px-2 ml-2'
        onClick={() => setOpenSettingsSidebar(true)}
      >
        <i className='fa-light fa-gear fs-6 mr-1' /> Settings
      </Button>
      <DashboardSettingsSidebar
        isOpenSidebar={openSettingsSidebar}
        toggleSidebar={toggleSettingsSidebar}
        dashboard={dashboard}
        handleSave={handleSave}
        handleDelete={handleDelete}
        hideOptions={hideOptions}
        availablePeriods={availablePeriods}
      />
    </>
  );
};
