import { useContext, useMemo, useState } from 'react';
import { CollapseButton, CollapseContent, CollapsePanel } from '@components/collapse-panel';
import { CHART_SUBTYPE, ChartEditorProps, QuestionsMapUtr } from '../types';
import { ChartData, isDisaggregatedRendering, transformVariables } from '@routes/custom-dashboard/utils';
import { ChartTypeSwitcher } from '../chart-type-switcher';
import { Input } from 'reactstrap';
import {
  ChartSubType,
  DashboardSourceOption,
  GridDashboardItemCreate,
  InsightDashboardItemChartType,
  InsightDashboardItemType,
} from '@g17eco/types/insight-custom-dashboard';
import { MetricsSelector } from '../metric-selector';
import { useGetInitiativeBlueprintQuestionsQuery } from '@api/admin-dashboard';
import { QueryError } from '@components/query/QueryError';
import Loader from '../../../components/loader';
import { UtrVariable } from '@routes/summary/insights/utils/constants';
import {
  doSupportMultiple,
  getUniqueUtrVariables,
  isMultipleMetricsChart,
  checkIsCustomTitle,
  getChartCalculation,
  getChartTitleByType,
  isValidUtrVariable,
} from '../utils/dashboard-utils';
import { EditorTemplate } from './EditorTemplate';
import { CustomDashboardContext } from '@routes/custom-dashboard/context/CustomDashboardWrapper';
import { getChartData } from '../utils/chart-utils';
import { QUESTION, SURVEY } from '@constants/terminology';
import { SelectFactory, SelectTypes, SimpleTooltip } from '@g17eco/molecules';

const MAX_LENGTH_CHART_TITLE = 50;
const TITLE_TOOLTIP = 'If left blank, title will default to data-point selected.';

const getTitle = ({
  chartData: { type, metrics },
  questionsMap,
}: {
  chartData: ChartData;
  questionsMap: Map<string, QuestionsMapUtr>;
}) => {
  if (!metrics || metrics.length === 0 || !type) {
    return '';
  }

  // Extract title from the first metric
  const [metric] = metrics;

  return getChartTitleByType({ chartType: type, utrVariable: metric, questionsMap });
};

export const ChartEditor = (props: ChartEditorProps) => {
  const { editingItem, handleCancel, updateItem, initiativeId, queryParams } = props;
  const [chartData, setChartData] = useState(() => getChartData(editingItem));
  const isMultiple = isMultipleMetricsChart(chartData);
  const isDisaggregated = isDisaggregatedRendering({ initiativeIds: queryParams.initiativeIds, initiativeId });

  const { userIsStaff, integrationProviders } = useContext(CustomDashboardContext);

  const integrationCount = integrationProviders?.length;
  const chartTypeOptions = useMemo(() => {
    const options: DashboardSourceOption[] = [
      { value: InsightDashboardItemType.Chart, label: `${SURVEY.CAPITALIZED_SINGULAR} ${QUESTION.PLURAL}` },
      { value: InsightDashboardItemType.SDGTracker, label: 'SDG Contribution' },
    ];
    if (userIsStaff && integrationCount) {
      options.push({ value: InsightDashboardItemType.Integration, label: 'Integration' });
    }
    return options;
  }, [userIsStaff, integrationCount]);

  const [sourceMetrics, setSourceMetrics] = useState(() => {
    return chartTypeOptions.reduce((acc, option) => {
      return { ...acc, [option.value]: chartData.type === option.value ? chartData.metrics : [] };
    }, {} as Record<InsightDashboardItemChartType, UtrVariable[] | undefined>);
  });

  const {
    data: blueprintQuestions,
    isFetching,
    isError,
    error,
  } = useGetInitiativeBlueprintQuestionsQuery({ initiativeId });

  const isDisabled = !chartData.metrics?.length;

  const questionsMap = useMemo(() => {
    const map = new Map<string, QuestionsMapUtr>();
    if (!blueprintQuestions?.length) {
      return map;
    }

    blueprintQuestions.forEach((question) => {
      map.set(question.code, question);
    });

    integrationProviders?.forEach(provider => {
      provider.questions.forEach(question => {
        map.set(question.code, question);
      });
    });

    return map;
  }, [blueprintQuestions, integrationProviders]);

  const isCustomTitle = checkIsCustomTitle({ item: editingItem, chartData, questionsMap });

  const changeChartData = (newChartData: Partial<ChartData>) => {
    setChartData((chartData) => ({ ...chartData, ...newChartData }));
  };

  const getCachedMetrics = (type: ChartData['type'], isMultiple: boolean) => {
    const typeMetrics = type ? sourceMetrics[type] ?? [] : [];
    return isMultiple ? typeMetrics : typeMetrics.slice(0, 1);
  };

  const onSubTypeChange = (subType: ChartSubType) => {
    changeChartData({ subType, metrics: getCachedMetrics(chartData.type, doSupportMultiple(subType)) });
  };

  const onSourceChange = (type: InsightDashboardItemChartType) => {
    // Load previously stored result of metrics if available.
    changeChartData({ type, metrics: getCachedMetrics(type, isMultiple) });
  };

  const onChangeChartData = (newChartData: Partial<ChartData>) => {
    const type = newChartData.type;
    if (type && newChartData.metrics) {
      // Cache results for the type
      setSourceMetrics((sourceMetrics) => ({ ...sourceMetrics, [type]: newChartData.metrics }));
    }
    changeChartData(newChartData);
  };

  const handleCreate = async () => {
    if (!chartData.metrics || !chartData.subType || !chartData.type) {
      return;
    }
    const uniqueVariables = getUniqueUtrVariables(chartData.metrics);
    const hasCustomTitle = isCustomTitle && chartData.title && !!chartData.title.trim();
    const type = chartData.type;

    if (isMultiple || isDisaggregated) {
      // If not set, use the title of the first metric
      const title = hasCustomTitle ? chartData.title : getTitle({ chartData, questionsMap });
      const variables = transformVariables(uniqueVariables);

      const calculation = getChartCalculation({
        metrics: chartData.metrics,
        variables,
        subType: chartData.subType,
        questionsMap,
        chartType: type,
      });

      const submitItem = {
        ...editingItem,
        subType: chartData.subType,
        title,
        variables,
        calculation,
        icon: undefined,
        type,
      } as GridDashboardItemCreate;
      return updateItem(submitItem);
    }

    const questionData = uniqueVariables[0];
    if (!isValidUtrVariable(questionData)) {
      return;
    }

    const variables = transformVariables([questionData]);
    const title = hasCustomTitle
      ? chartData.title
      : getChartTitleByType({ chartType: type, utrVariable: questionData, questionsMap });

    const { subType } = chartData;

    const submitItem = {
      ...editingItem,
      type,
      title,
      variables,
      subType,
      icon: '',
      // unset calculation as single utr chart must not have calculation otherwise it will render multi utrs chart instead
      calculation: undefined,
    } as GridDashboardItemCreate;

    return updateItem(submitItem);
  };

  return (
    <EditorTemplate isDisabled={isDisabled} handleCancel={handleCancel} handleSubmit={handleCreate}>
      {isError ? <QueryError error={error} type={'danger'} /> : null}
      {isFetching ? <Loader /> : null}
      <CollapsePanel className='comment-group comments py-3' collapsed={false}>
        <CollapseButton>
          <>{CHART_SUBTYPE[chartData.subType as keyof typeof CHART_SUBTYPE]}</>
        </CollapseButton>
        <CollapseContent>
          <ChartTypeSwitcher chartSubType={chartData.subType} onSubTypeChange={onSubTypeChange} />
          <p className='mt-3 text-medium'>
            Title (optional)
            <SimpleTooltip text={TITLE_TOOLTIP} className='text-ThemeIconSecondary'>
              <i className='fal fa-info-circle ml-2' />
            </SimpleTooltip>
          </p>
          <Input
            type='text'
            className='text-md'
            maxLength={MAX_LENGTH_CHART_TITLE}
            value={chartData.title ?? ''}
            onChange={(e) => changeChartData({ title: e.target.value })}
            placeholder='Enter chart title'
          />
          <p className='mt-3 text-medium'>Source</p>
          <SelectFactory
            selectType={SelectTypes.SingleSelect}
            placeholder={'Select chart source'}
            options={chartTypeOptions}
            value={chartTypeOptions.find((option) => option.value === chartData.type) ?? null}
            onChange={(option) => (option?.value ? onSourceChange(option.value) : undefined)}
          />
          <MetricsSelector
            {...props}
            chartData={chartData}
            changeChartData={onChangeChartData}
            questionsMap={questionsMap}
          />
        </CollapseContent>
      </CollapsePanel>
    </EditorTemplate>
  );
};
