import Chart from 'react-google-charts';
import { ChartDataLoaded, GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import { chartStyleNames, getChartOptions } from '@routes/summary/insights/utils/chartStyles';
import { ChartType } from '@routes/summary/insights/utils/constants';
import { TakenFromText } from './common/TakenFromText';
import { useEffect, useState } from 'react';
import { ReactGoogleChartProps } from 'react-google-charts/dist/types';
import { BaseHistoricalUtr, DataProps } from '../types';
import { Note } from './common/Note';
import { FormulaVariables } from '@utils/formula';

export interface BarChartDataLoaded<T = unknown> extends ChartDataLoaded<T> {
  variablesWithValues?: FormulaVariables;
}

export type Props<T> = Pick<GridDashboardChartItem, 'calculation' | 'variables' | 'note'> & {
  utrsData: T[];
  overrideOptions?: ReactGoogleChartProps['options'];
  getChartData: (props: DataProps<T>) => Promise<BarChartDataLoaded>;
};

export const BarChart = <T extends BaseHistoricalUtr>({
  getChartData,
  utrsData,
  variables,
  calculation,
  note,
  overrideOptions,
}: Props<T>) => {
  const [data, setData] = useState<BarChartDataLoaded | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation }).then((data) => setData(data));
    }
    return () => {
      setData(undefined);
    };
  }, [calculation, getChartData, utrsData, variables]);

  if (!utrsData.length || !variables || !calculation || !data) {
    return null;
  }

  const { chartData, effectiveDate = '', period, variablesWithValues } = data;

  return (
    <>
      {variablesWithValues ? <Note note={note} variablesWithValues={variablesWithValues} /> : null}
      <div className='w-100 h-100'>
        <Chart
          chartType={ChartType.BarChart}
          data={chartData}
          width={'100%'}
          height={'100%'}
          options={{
            ...getChartOptions({
              chartType: ChartType.BarChart,
              options: chartStyleNames.chartStyleHorizontalBar,
            }),
            ...overrideOptions,
          }}
        />
      </div>
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </>
  );
};
