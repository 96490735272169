import { FormGroup } from 'reactstrap'
import { CheckboxInput } from '../../form/CheckboxInput'
import { CheckboxProps, updateFn } from '../../form/common';
import { TemplateConfigFormData } from '../../survey-templates/types';
import { ConfigFormData } from '../types';
import { QUESTION } from '@constants/terminology';

interface SurveyQuestionFormProps {
  disabled: boolean;
  isCheckboxDisabled: boolean;
  checkOptions: Pick<CheckboxProps, 'options' | 'groupCode'>;
  form:
    | ConfigFormData
    | TemplateConfigFormData
    | Pick<ConfigFormData, 'verificationRequired' | 'evidenceRequired' | 'noteRequired' | 'isPrivate'>;
  updateForm: updateFn;
}

export const SurveyQuestionForm = (props: SurveyQuestionFormProps) => {
  const { disabled, isCheckboxDisabled, checkOptions, form, updateForm } = props;

  return <FormGroup className='mt-4' disabled={disabled}>
    <h5>Default {QUESTION.SINGULAR} requirements</h5>
    <div className='ml-2'>
      <CheckboxInput
        disabled={isCheckboxDisabled}
        groupCode={checkOptions.groupCode}
        options={checkOptions.options}
        form={form}
        onChange={updateForm}
      />
    </div>
  </FormGroup>
}