import { Button } from 'reactstrap';
import { CustomReportTemplateType } from './types';

const options = [
  {
    label: 'Default',
    value: CustomReportTemplateType.Default,
    icon: 'fa-table-columns',
  },
  {
    label: 'Default (transposed)',
    value: CustomReportTemplateType.Transposed,
    icon: 'fa-table-rows',
  },
  {
    label: 'Tabular',
    value: CustomReportTemplateType.Tabular,
    icon: 'fa-table',
  },
];

export const TemplateType = ({
  templateType,
  onChangeTemplateType,
}: {
  templateType: CustomReportTemplateType;
  onChangeTemplateType: (type: CustomReportTemplateType) => void;
}) => {
  return (
    <>
      <h5 className='mt-4'>Template</h5>
      <div className='d-flex gap-2'>
        {options.map((op) => (
          <Button key={op.value} onClick={() => onChangeTemplateType(op.value)} active={templateType === op.value}>
            <i className={`fal ${op.icon} text-ThemeIconSecondary mr-1`} />
            {op.label}
          </Button>
        ))}
      </div>
    </>
  );
};
