import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { UnitConfig } from '@models/surveyData';
import { convertValue, getAvailableUnits, getUnitsForType, UnitOption } from '@utils/units';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Papa from 'papaparse';

type ExtendedAllowedUnit = keyof Omit<UnitConfig, 'currency'>;

type ConversionState = {
  sourceUnit: string;
  targetUnit: string;
  sourceNumber: number;
  targetNumber: number;
};

const DEFAULT_SOURCE_NUMBER = 1;

const mapUnitsLabel: Record<ExtendedAllowedUnit, string> = {
  area: 'Area',
  length: 'Length',
  mass: 'Mass',
  volume: 'Volume',
  time: 'Time',
  energy: 'Energy',
  co2Emissions: 'CO2 Equivalent',
  numberScale: 'Number Scale',
  partsPer: 'Concentration / Parts Per',
};

const unitTypeOptions = Object.entries(mapUnitsLabel).map(([value, label]) => ({
  label,
  value: value as ExtendedAllowedUnit,
}));

export const getUnitConversionOptions = (unitOption: ExtendedAllowedUnit) =>
  (getUnitsForType(unitOption) as UnitOption[]).map(({ abbr, singular }) => ({
    label: singular,
    value: abbr,
  }));

interface ConversionUnitRowProps {
  selectOptions: { label: string; value: string }[];
  selectValue: string;
  onSelectChange: (value: string) => void;
  inputValue: number;
  onInputChange: (value: string) => void;
  onInputConvert: (value: string) => void;
}

const ConversionUnitRow = ({
  selectOptions,
  selectValue,
  onSelectChange,
  inputValue,
  onInputChange,
  onInputConvert,
}: ConversionUnitRowProps) => (
  <div className='d-flex gap-2'>
    <SelectFactory
      selectType={SelectTypes.SingleSelect}
      options={selectOptions}
      onChange={(op) => onSelectChange(op?.value ?? '')}
      value={selectOptions.find((op) => op.value === selectValue)}
      isSearchable={false}
      className='col-8'
    />
    <Input
      type='number'
      className='lh-sm'
      value={inputValue}
      onChange={(e) => onInputChange(e.target.value)}
      onBlur={(e) => onInputConvert(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onInputConvert(e.currentTarget.value);
        }
      }}
    />
  </div>
);

interface ConversionModalProps {
  isOpen: boolean;
  toggle: () => void;
  defaultsUnitConfig: UnitConfig;
}

export const ConversionModal = ({ isOpen, toggle, defaultsUnitConfig }: ConversionModalProps) => {
  const [unitOption, setUnitOption] = useState<ExtendedAllowedUnit>('area');

  const unitConversionOptions = getUnitConversionOptions(unitOption);

  const getConversionState = (unitType: ExtendedAllowedUnit): ConversionState => {
    const sourceUnit = defaultsUnitConfig[unitType as keyof UnitConfig];
    const targetUnit = getUnitConversionOptions(unitType)[0]?.value;
    return {
      sourceUnit,
      targetUnit,
      sourceNumber: DEFAULT_SOURCE_NUMBER,
      targetNumber: convertValue(DEFAULT_SOURCE_NUMBER, sourceUnit, targetUnit),
    };
  };

  const [{ sourceUnit, targetUnit, sourceNumber, targetNumber }, setConversionState] = useState<ConversionState>(
    getConversionState(unitOption)
  );

  const updateConversionNumberValue = (key: 'sourceNumber' | 'targetNumber') => (value: string) => {
    setConversionState((prevState) => {
      const numberValue = value ? Number(value) : '';

      const [sourceKey, targetKey, sourceUnit, targetUnit] =
        key === 'sourceNumber'
          ? ['sourceNumber', 'targetNumber', prevState.sourceUnit, prevState.targetUnit]
          : ['targetNumber', 'sourceNumber', prevState.targetUnit, prevState.sourceUnit];

      const newState = {
        ...prevState,
        [sourceKey]: numberValue,
        [targetKey]: numberValue ? convertValue(numberValue, sourceUnit, targetUnit) : '',
      };

      return newState;
    });
  };

  const handleInputChange = (key: 'sourceNumber' | 'targetNumber') => (value: string) => {
    const numberValue = value ? Number(value) : '';
    setConversionState((prevState) => ({
      ...prevState,
      [key]: numberValue,
    }));
  };

  const handleSelectChange = (key: 'sourceUnit' | 'targetUnit') => (value: string) => {
    setConversionState((prevState) => {
      const updatedConversion = {
        ...prevState,
        [key]: value,
      };
      return {
        ...updatedConversion,
        targetNumber: convertValue(updatedConversion.sourceNumber, updatedConversion.sourceUnit, updatedConversion.targetUnit),
      };
    });
  };

  const handleDownload = () => {
    const availableUnits = getAvailableUnits();
    const csvData = Papa.unparse({
      fields: ['Unit', 'Unit Type', 'Code', 'Conversion'],
      data: availableUnits.map((unit) => {
        const isEmptyNumberScale = unit.unit === 'numberScale' && unit.unitType === ''
        return {
          Unit: mapUnitsLabel[unit.unit as ExtendedAllowedUnit],
          'Unit Type': isEmptyNumberScale ? 'Single' : unit.unitType,
          Code: isEmptyNumberScale ? '-' : unit.code,
          Conversion: unit.conversion === 1 ? '1 (Default)' : unit.conversion,
        }
      }),
    });
    FileSaver.saveAs(new Blob([csvData], { type: 'text/csv;charset=utf-8;' }), 'G17eco Company Tracker Conversions');
  };

  const handleCloseModal = () => {
    toggle();
    setUnitOption('area');
    setConversionState(getConversionState('area'));
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCloseModal} backdrop='static' centered>
      <ModalHeader toggle={handleCloseModal}>Review conversion rates</ModalHeader>
      <ModalBody data-testid='conversion-modal-body'>
        <div className='mb-3'>
          <h6 className='text-black'>Select unit</h6>
          <SelectFactory<ExtendedAllowedUnit>
            selectType={SelectTypes.SingleSelect}
            options={unitTypeOptions}
            onChange={(op) => {
              const selectedUnitOption = op?.value ?? 'area';
              setUnitOption(selectedUnitOption);
              setConversionState(getConversionState(selectedUnitOption));
            }}
            value={unitTypeOptions.find((op) => op.value === unitOption)}
            isSearchable={false}
          />
        </div>
        <div>
          <h6 className='text-black'>Select conversions</h6>
          <div className='d-flex flex-column gap-2'>
            <ConversionUnitRow
              selectOptions={unitConversionOptions}
              selectValue={sourceUnit}
              onSelectChange={handleSelectChange('sourceUnit')}
              inputValue={sourceNumber}
              onInputChange={handleInputChange('sourceNumber')}
              onInputConvert={updateConversionNumberValue('sourceNumber')}
            />
            <ConversionUnitRow
              selectOptions={unitConversionOptions}
              selectValue={targetUnit}
              onSelectChange={handleSelectChange('targetUnit')}
              inputValue={targetNumber}
              onInputChange={handleInputChange('targetNumber')}
              onInputConvert={updateConversionNumberValue('targetNumber')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='w-100 d-flex justify-content-between'>
          <Button color='link' onClick={handleDownload}>
            <i className='fal fa-file-csv mr-2 text-sm' />
            Download all conversion rates
          </Button>
          <Button color='transparent' onClick={handleCloseModal}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
