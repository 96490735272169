import { Button, Input } from 'reactstrap';
import { getPeriodName } from '@utils/universalTracker';
import { Link } from 'react-router-dom';
import { SurveyStats } from '@api/initiative-stats';
import { DATE, formatDate } from '@utils/date';
import { naturalSort } from '@utils/index';
import { generateUrl } from '@routes/util';
import { ROUTES } from '@constants/routes';
import { Table, ColumnDef } from '@g17eco/molecules';

type SurveysTableProps = {
  surveys: SurveyStats[];
  selectedSurveys: string[];
  setSelectedSurveys: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SurveysTable = (props: SurveysTableProps) => {
  const { surveys, selectedSurveys, setSelectedSurveys } = props;

  const toggleSelect = (surveyId: string) => {
    if (!selectedSurveys.includes(surveyId)) {
      setSelectedSurveys((prev) => [...prev, surveyId]);
      return;
    }

    setSelectedSurveys((prev) => prev.filter((id) => id !== surveyId));
  };

  const isSelectedAll = selectedSurveys.length === surveys.length;
  const toggleSelectAll = () => {
    setSelectedSurveys(isSelectedAll ? [] : surveys.map(({ _id }) => _id));
  };
  const columns: ColumnDef<SurveyStats>[] = [
    {
      header: 'Type',
      accessorFn: (row: SurveyStats) => getPeriodName(row.period, false),
      meta: {
        cellProps: {
          style: {
            textAlign: 'center',
          },
        },
      },
    },
    {
      header: 'Up to',
      accessorFn: (row) => formatDate(row.effectiveDate, DATE.MONTH),
      meta: {
        cellProps: {
          style: {
            textAlign: 'center',
          },
        },
      },
      sortingFn: (a, b) => naturalSort(a.original.effectiveDate, b.original.effectiveDate),
    },
    {
      accessorKey: 'name',
      header: 'Title',
      meta: {
        cellProps: {
          style: {
            maxWidth: 250,
          },
        },
      },
      sortingFn: (a, b) =>
        naturalSort(a.original.name ?? a.original.initiativeName, b.original.name ?? b.original.initiativeName),
      cell: ({ row }) => {
        const data = row.original;
        const url = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, { initiativeId: data.initiativeId, surveyId: data._id });
        return (
          <Link to={url}>
            <span className={'text-truncate'} style={{ display: 'block' }}>
              {data.name ?? data.initiativeName ?? '-'}
            </span>
          </Link>
        );
      },
    },
    {
      accessorKey: 'status.created',
      header: () => <i className='fa fa-user-clock text-secondary' />,
      meta: {
        cellProps: {
          style: {
            textAlign: 'right',
          },
        },
      },
      cell: ({ row }) => (
        <div style={{ width: '100%', borderLeft: '1px solid #E6E8ED', padding: '3px 0' }}>
          {row.original.status.created || '-'}
        </div>
      ),
    },
    {
      id: 'status.updated',
      header: () => <i className='fa fa-user-edit text-primary' />,
      accessorFn: (row) => row.status.updated || '-',
      meta: {
        cellProps: {
          style: {
            textAlign: 'right',
          },
        },
      },
    },
    {
      id: 'status.rejected',
      header: () => <i className='fa fa-user-times text-danger' />,
      accessorFn: (row) => row.status.rejected || '-',
      meta: {
        cellProps: {
          style: {
            textAlign: 'right',
          },
        },
      },
    },
    {
      id: 'status.verified',
      header: () => <i className='fa fa-user-check text-success' />,
      accessorFn: (row) => row.status.verified || '-',
      meta: {
        cellProps: {
          style: {
            textAlign: 'right',
          },
        },
      },
    },
    {
      id: 'select',
      header: () => (
        <Button onClick={toggleSelectAll} color={'link'} className='btn-xs'>
          {isSelectedAll ? 'Deselect' : 'Select'} all
        </Button>
      ),
      meta: {
        cellProps: {
          style: {
            textAlign: 'center',
          },
        },
      },
      cell: ({ row }) => (
        <div style={{ width: '100%', borderLeft: '1px solid #E6E8ED', padding: '3px 0' }}>
          <Input
            {...{
              type: 'checkbox',
              checked: selectedSurveys.includes(row.original._id),
              onChange: () => toggleSelect(row.original._id),
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      pageSize={100}
      columns={columns}
      data={surveys}
      noData={<>No results</>}
      className='surveys-editing__table'
    />
  );
};
