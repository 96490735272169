import { SURVEY } from '@constants/terminology';
import { useToggle } from '@hooks/useToggle';
import { Button } from 'reactstrap';
import { AddingSourceModal } from './AddingSourceModal';
import { SurveyListItem } from '@g17eco/types/survey';
import { Form, OnChangeForm } from './types';
import { DATE, formatDateUTC } from '@utils/date';
import { getPeriodName } from '@utils/universalTracker';
import { getSurveyTypeLabel } from '@utils/survey';
import classNames from 'classnames';
import { ColumnDef, Table } from '@g17eco/molecules';
import { naturalSort } from '@utils/index';

interface Props {
  form: Pick<Form, 'surveyIds' | '_id'>;
  surveys: SurveyListItem[];
  onChangeSource: OnChangeForm;
}

export const ReportDataSource = ({ form, surveys, onChangeSource }: Props) => {
  const { surveyIds, _id } = form;
  const [open, toggle] = useToggle();
  const handleRemove = (id: string) => {
    onChangeSource({ surveyIds: surveyIds.filter((surveyId) => surveyId !== id) });
  };

  const columns: ColumnDef<SurveyListItem>[] = [
    {
      header: 'Name',
      cell: (c) => <div className='text-truncate'>{c.row.original.name}</div>,
      meta: {
        cellProps: {
          style: {
            maxWidth: 300,
          },
        },
      },
    },
    {
      header: 'Effective date',
      accessorFn: (row) => formatDateUTC(row.effectiveDate, DATE.MONTH_YEAR),
      sortingFn: (a, b) => naturalSort(a.original.effectiveDate, b.original.effectiveDate)
    },
    {
      header: 'Period',
      accessorFn: (row) => getPeriodName(row.period, false),
    },
    {
      header: 'Type',
      accessorFn: (row) => getSurveyTypeLabel(row.type ?? ''),
    },
    {
      header: 'Status',
      accessorFn: (row) => (row.completedDate ? 'Completed' : 'In Progress'),
    },
    {
      id: 'buttons',
      header: '',
      cell: (c) => (
        <i className='fal fa-trash text-danger cursor-pointer' onClick={() => handleRemove(c.row.original._id)} />
      ),
      meta: {
        cellProps: {
          className: 'text-right',
        },
      },
    },
  ];

  return (
    <div>
      <h5 className='mt-4'>Data source</h5>
      {!surveyIds.length ? (
        <p className={classNames(_id ? 'text-ThemeDangerMedium' : 'text-ThemeTextMedium', 'mt-3 mb-0')}>
          No {SURVEY.PLURAL} have been selected for this download.
        </p>
      ) : (
        <Table
          columns={columns}
          responsive
          data={surveys.filter((survey) => surveyIds.includes(survey._id))}
          showHeader={false}
          className='template-builder__data-source-table'
        />
      )}
      <Button color='secondary' size={'sm'} className='mt-3' onClick={toggle}>
        <i className='fal fa-file-circle-plus' /> Add source {SURVEY.SINGULAR}
      </Button>
      <AddingSourceModal
        key={`AddingSourceModal-${open}`}
        open={open}
        toggle={toggle}
        surveyIds={surveyIds}
        surveys={surveys}
        onChangeSource={onChangeSource}
      />
    </div>
  );
};
