import { SURVEY } from '@constants/terminology';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, OnChangeForm } from './types';
import { SurveyListItem, SurveyType } from '@g17eco/types/survey';
import { ColumnDef, Table, Option, SelectFactory, SelectTypes } from '@g17eco/molecules';
import {
  DATE,
  formatDateUTC,
  getMonth,
  getMonthOptions,
  getPeriodOptions,
  getYear,
  getYearOptionsByRange,
} from '@utils/date';
import { getPeriodName } from '@utils/universalTracker';
import { getSurveyTypeLabel } from '@utils/survey';
import { Checkbox, CheckboxState } from '@g17eco/atoms';
import { useState } from 'react';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { isArraysEqual } from '@utils/array';
import { naturalSort } from '@utils/index';

const monthOptions: Option<number>[] = getMonthOptions(true);
const periodOptions: Option<DataPeriods>[] = getPeriodOptions(false);
const typeOptions: Option<SurveyType.Default | SurveyType.Aggregation>[] = [
  { label: getSurveyTypeLabel(SurveyType.Default), value: SurveyType.Default },
  { label: getSurveyTypeLabel(SurveyType.Aggregation), value: SurveyType.Aggregation },
];

interface Filters {
  month?: number;
  year?: number;
  period?: DataPeriods;
  type?: SurveyType.Default | SurveyType.Aggregation;
}

type Props = Pick<Form, 'surveyIds'> & {
  open: boolean;
  toggle: () => void;
  surveys: SurveyListItem[];
  onChangeSource: OnChangeForm;
};
export const AddingSourceModal = ({ open, toggle, surveyIds, surveys, onChangeSource }: Props) => {
  const [selectingIds, setSelectingIds] = useState<string[]>(surveyIds);

  const toggleSelect = (id: string) => {
    setSelectingIds((selectingIds) =>
      selectingIds.includes(id) ? selectingIds.filter((sid) => sid !== id) : [...selectingIds, id]
    );
  };
  const handleSubmit = () => {
    toggle();
    onChangeSource({ surveyIds: selectingIds });
  };

  const columns: ColumnDef<SurveyListItem>[] = [
    {
      header: 'Date',
      accessorFn: (row) => formatDateUTC(row.effectiveDate, DATE.MONTH_YEAR_SHORT),
      sortingFn: (a, b) => naturalSort(a.original.effectiveDate, b.original.effectiveDate),
    },
    {
      header: 'Period',
      accessorFn: (row) => getPeriodName(row.period, false),
    },
    {
      header: 'Type',
      accessorFn: (row) => getSurveyTypeLabel(row.type ?? ''),
    },
    {
      id: 'buttons',
      header: '',
      cell: (c) => (
        <Checkbox
          status={selectingIds.includes(c.row.original._id) ? CheckboxState.Checked : CheckboxState.Unchecked}
          onChange={() => toggleSelect(c.row.original._id)}
        />
      ),
    },
  ];

  const [filters, setFilters] = useState<Filters>({});
  const { period, type, month, year } = filters;

  const yearOptions = !surveys.length
    ? []
    : getYearOptionsByRange(surveys[0].effectiveDate, surveys[surveys.length - 1]?.effectiveDate, true);

  const filteredSurveys = surveys.filter((survey) => {
    return !(
      (period && survey.period !== period) ||
      (type && survey.type !== type) ||
      (month !== undefined && getMonth(survey.effectiveDate, true) !== month) ||
      (year !== undefined && getYear(survey.effectiveDate, true) !== year)
    );
  });

  const disabled = !selectingIds.length || isArraysEqual(selectingIds, surveyIds);

  return (
    <Modal isOpen={open} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Adding source {SURVEY.SINGULAR}</ModalHeader>
      <ModalBody>
        <div className='row g-1 mb-3'>
          <div className='col-3'>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              isSearchable={false}
              placeholder='Month'
              onChange={(option) => setFilters({ ...filters, month: option?.value })}
              value={monthOptions.find((o) => o.value === filters.month)}
              options={monthOptions}
              isFlexibleSize
              isClearable
            />
          </div>
          <div className='col-3'>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              isSearchable={false}
              placeholder='Year'
              onChange={(option) => setFilters({ ...filters, year: option?.value })}
              value={yearOptions.find((o) => o.value === filters.year)}
              options={yearOptions}
              isFlexibleSize
              isClearable
            />
          </div>
          <div className='col-3'>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              isSearchable={false}
              placeholder='Period'
              onChange={(option) => setFilters({ ...filters, period: option?.value })}
              value={periodOptions.find((o) => o.value === filters.period)}
              options={periodOptions}
              isFlexibleSize
              isClearable
            />
          </div>
          <div className='col-3'>
            <SelectFactory
              selectType={SelectTypes.SingleSelect}
              isSearchable={false}
              placeholder='Type'
              onChange={(option) => setFilters({ ...filters, type: option?.value })}
              value={typeOptions.find((o) => o.value === filters.type)}
              options={typeOptions}
              isFlexibleSize
              isClearable
            />
          </div>
        </div>
        <Table
          columns={columns}
          responsive
          data={filteredSurveys}
          noData='No available data sources'
          className='overflow-scroll'
          style={{ maxHeight: '40vh' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSubmit} disabled={disabled}>
          Add data source
        </Button>
      </ModalFooter>
    </Modal>
  );
};
