import Chart from 'react-google-charts';
import { ChartDataLoaded, ChartSubType, GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import { chartStyleNames, getChartOptions } from '@routes/summary/insights/utils/chartStyles';
import { ChartType } from '@routes/summary/insights/utils/constants';
import { useEffect, useState } from 'react';
import { ReactGoogleChartProps } from 'react-google-charts/dist/types';
import { BaseHistoricalUtr, DataProps } from '../types';
import { TakenFromText } from './common/TakenFromText';

type Props<T> = Pick<GridDashboardChartItem, 'calculation' | 'variables'> & {
  utrsData: T[];
  subType: ChartSubType.FullPie | ChartSubType.Pie | ChartSubType.PieWithLegend;
  overrideOptions?: ReactGoogleChartProps['options'];
  getChartData: (props: DataProps<T>) => Promise<ChartDataResponse>;
};

type ChartColumn = string | number;
export type ChartDataResponse = ChartDataLoaded<ChartColumn>;

const CHART_SUBTYPE_STYLE_NAME_MAP = {
  [ChartSubType.FullPie]: chartStyleNames.chartStyleFullPie,
  [ChartSubType.PieWithLegend]: chartStyleNames.chartStylePieLegend,
  [ChartSubType.Pie]: '',
} as const;

export const PieChart = <T extends BaseHistoricalUtr>({
  utrsData,
  variables,
  calculation,
  subType,
  overrideOptions,
  getChartData,
}: Props<T>) => {
  const [data, setData] = useState<ChartDataResponse | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation }).then((data) => setData(data));
    }
    return () => {
      setData(undefined);
    };
  }, [calculation, getChartData, utrsData, variables]);

  if (!utrsData.length || !variables || !calculation || !data) {
    return null;
  }

  const { chartData, effectiveDate = '', period } = data;

  return (
    <>
      <div className='w-100 h-100'>
        <Chart
          chartType={ChartType.PieChart}
          data={chartData}
          width={'100%'}
          height={'100%'}
          options={{
            ...getChartOptions({
              chartType: ChartType.PieChart,
              options: chartStyleNames[CHART_SUBTYPE_STYLE_NAME_MAP[subType]],
            }),
            ...overrideOptions,
          }}
        />
      </div>
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </>
  );
};
