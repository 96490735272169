import { Input } from 'reactstrap';
import { CustomReportTemplateType, Form, OnChangeForm } from './types';
import { ReportDataSource } from './ReportDataSource';
import { Filters } from './Filters';
import { useSelector } from 'react-redux';
import { RootState } from '@reducers/index';
import { getScopesDifferent, getScopeModules, mergeSurveysScopes } from '@utils/survey-scope';
import { useGetMetricGroupsQuery } from '@api/metric-groups';
import { TemplateType } from './TemplateType';
import { isTabularTemplate, isTransposedTemplate } from './utils';

interface Props {
  initiativeId: string;
  form: Form;
  onChangeForm: OnChangeForm;
}

export const Configuration = ({ initiativeId, form, onChangeForm }: Props) => {
  const { name, description, surveyIds, metricFilters, templateType, columns } = form;
  const { data: surveys } = useSelector((state: RootState) => state.initiativeSurveyList);
  const { data: metricGroups = [] } = useGetMetricGroupsQuery(initiativeId);

  const getScope = (surveyIds: string[]) =>
    mergeSurveysScopes(
      surveys.filter((survey) => surveyIds.includes(survey._id)),
      { parentGroupsOnly: true }
    );
  const originalScope = getScope(surveyIds);
  const unSelectedScope = getScopesDifferent(form.metricFilters.scope, originalScope);

  const handleChangeSource: OnChangeForm = ({ surveyIds: newSurveyIds = [] }) => {
    onChangeForm({
      surveyIds: newSurveyIds,
      metricFilters: {
        ...metricFilters,
        scope: getScopesDifferent(unSelectedScope, getScope(newSurveyIds)),
      },
    });
  };

  const getUpdatedColumns = (templateType: CustomReportTemplateType) => {
    if (isTransposedTemplate(templateType)) {
      return columns;
    }

    const excludedColumns = isTabularTemplate(templateType) ? ['row', 'columnLabel'] : ['row'];
    return columns.filter((col) => !excludedColumns.includes(col.code));
  };

  const handleChangeTemplateType = (templateType: CustomReportTemplateType) => {
    onChangeForm({ templateType, columns: getUpdatedColumns(templateType) });
  };

  return (
    <>
      <Input
        type='text'
        placeholder='Custom report name'
        value={name}
        onChange={(e) => onChangeForm({ name: e.target.value })}
      />
      <Input
        type='textarea'
        placeholder='Report description (optional)'
        value={description}
        onChange={(e) => onChangeForm({ description: e.target.value })}
        className='mt-3'
      />

      <ReportDataSource surveys={surveys} form={form} onChangeSource={handleChangeSource} />
      <TemplateType templateType={templateType} onChangeTemplateType={handleChangeTemplateType} />
      <Filters
        filters={metricFilters}
        onChangeForm={onChangeForm}
        scopeModules={getScopeModules({ scope: originalScope, metricGroups })}
      />
    </>
  );
};
