import { useParams } from 'react-router-dom';
import { formatDate } from '@utils/date';
import { RouteParams, TemplateTableStats } from '../types';
import { TemplateDropdownOptions } from './TemplateDropdownOptions';
import { useGetTemplatesQuery } from '@api/survey-templates';
import { Table, ColumnDef } from '@g17eco/molecules';
import { naturalSort } from '@utils/index';

const columns: ColumnDef<TemplateTableStats>[] = [
  {
    accessorKey: 'name',
    header: 'Templates',
  },
  {
    accessorKey: 'type',
    header: 'Template type',
  },
  {
    header: 'Level created',
    accessorFn: (row) => row.initiative.name,
  },
  {
    header: 'Created date',
    accessorFn: (row) => formatDate(row.created),
    sortingFn: (a, b) => naturalSort(a.original.created, b.original.created),
  },
  {
    header: 'Last edit',
    accessorFn: (row) => formatDate(row.lastUpdated),
    sortingFn: (a, b) => naturalSort(a.original.lastUpdated, b.original.lastUpdated),
  },
  {
    id: 'settings',
    header: '',
    cell: ({ row }) => <TemplateDropdownOptions template={row.original} />,
  },
];

export const TemplateTable = () => {
  const { initiativeId } = useParams<RouteParams>();
  const query = useGetTemplatesQuery(initiativeId);

  return (
    <Table
      className='mt-3'
      responsive={true}
      pageSize={10}
      columns={columns}
      data={query.data ?? []}
    />
  );
};
