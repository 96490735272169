import { ChartDataLoaded, GridDashboardChartItem } from '@g17eco/types/insight-custom-dashboard';
import { getChartOptions, chartStyleNames } from '@routes/summary/insights/utils/chartStyles';
import { ChartType } from '@routes/summary/insights/utils/constants';
import { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { ReactGoogleChartProps } from 'react-google-charts/dist/types';
import { BaseHistoricalUtr, DataProps } from '../types';
import { getVAxisTitle } from './multi-utrs-chart/utils';

type Props<T> = Pick<GridDashboardChartItem, 'calculation' | 'variables' | 'unitText'> & {
  utrsData: T[];
  isStacked?: boolean;
  overrideOptions?: ReactGoogleChartProps['options'];
  getChartData: (props: DataProps<T>) => Promise<ChartDataLoaded>;
};

export const ColumnChart = <T extends BaseHistoricalUtr>({
  getChartData,
  isStacked,
  overrideOptions,
  calculation,
  utrsData,
  variables,
  unitText,
}: Props<T>) => {
  const [data, setData] = useState<ChartDataLoaded | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation }).then((data) => setData(data));
    }
    return () => {
      setData(undefined);
    };
  }, [calculation, getChartData, utrsData, variables]);

  if (!calculation || !data?.chartData.length) {
    return null;
  }

  const vAxisTitle = getVAxisTitle({ unitText, calculation, utrsData });

  return (
    <div className='w-100 h-100'>
      <Chart
        chartType={ChartType.ColumnChart}
        data={data.chartData}
        width={'100%'}
        height={'100%'}
        options={{
          ...getChartOptions({
            chartType: ChartType.ColumnChart,
            vAxisTitle,
            options: isStacked ? chartStyleNames.chartStyleStacked : undefined,
          }),
          ...overrideOptions,
        }}
      />
    </div>
  );
};
