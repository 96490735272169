// This component is old version of DelegationModal which is copied over here to avoid break SurveyDelegation.

import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Loader from '../../loader';
import { getSafeErrorMessage } from '@actions/error';
import { SurveyDelegationSearchModal } from '../../search-modal';
import { SurveyUserRoles, UserMin } from '@constants/users';
import { roles } from '@constants/roles';
import { getCurrentUser, isUserManagerByInitiativeId } from '@selectors/user';
import { getAnalytics } from '@services/analytics/AnalyticsService';
import { AnalyticsEvents } from '@services/analytics/AnalyticsEvents';
import { connect, ConnectedProps } from 'react-redux';
import { SurveyActionData } from '../../../model/surveyData';
import { Action } from '@constants/action';
import G17Client from '@services/G17Client';
import { RootState } from '@reducers/index';
import { SurveyPermissions } from '@services/permissions/SurveyPermissions';
import './styles.scss';
import { ellipsis, naturalSort } from '@utils/index';
import { SimpleTooltip, Table } from '@g17eco/molecules';
import { getFullName } from '@utils/user';

const analytics = getAnalytics();

// We always need surveyId, but roles or stakeholders are optional when utrvs are provided
type DelegationModalSurvey = Pick<SurveyActionData, '_id' | 'initiativeId' | 'roles' | 'stakeholders'>;

interface DelegationModalProps {
  isOpen: boolean;
  initiativeId: string;
  toggle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSubmit?: () => void;
  title?: string;
  description?: string;
  survey: DelegationModalSurvey;
  headerTitle?: string;
  surveyRole: SurveyUserRoles;
}

type Props = PropsFromRedux & DelegationModalProps;

interface DelegationModalState {
  users: User[];
  ignoredIds: string[];
  loading: boolean;
  message?: string;
  error: boolean;
  errorMessage: string;
  searchModal: boolean;
  searchModalTitle: string;
  stakeholderType?: string;
  activeUserRole?: SurveyUserRoles;
}

interface User extends UserMin {
  email?: string;
}

interface ApiUrls {
  action: Action;
  userRole: SurveyUserRoles;
  userId?: string;
  email?: string;
}

class SurveyDelegationModal extends Component<Props, DelegationModalState> {
  _isMounted = true;

  static defaultProps = {
    isOpen: false,
    handleSubmit: () => {},
    toggle: () => {},
    title: '',
    initiativeId: '',
    headerTitle: 'Delegation',
    isManager: false,
  };

  handleError = (e: any) => this.setState({ error: true, errorMessage: getSafeErrorMessage(e) });

  state: DelegationModalState = {
    users: [],
    ignoredIds: [],
    loading: true,
    error: false,
    errorMessage: '',
    searchModal: false,
    searchModalTitle: 'Add a user',
    stakeholderType: undefined,
  };

  componentDidMount() {
    const { survey } = this.props;
    if (survey) {
      this.reloadUsers();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { survey } = this.props;
    if (!survey) {
      return;
    }


    if (prevProps.survey && prevProps.survey._id === survey._id) {
      return;
    }

    this.reloadUsers();
  }

  reloadUsers() {
    const { survey } = this.props;

    if (!survey?._id) {
      return;
    }

    if (this._isMounted) {
      this.setState({ error: false });
    }

    return G17Client.get(`surveys/${survey._id}/users`)
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
            users: response.data.data.users ?? [],
          });
        }
      })
      .catch(this.handleError);
  }

  getAnalyticsPayload = (stakeholderType: SurveyUserRoles) => {
    const { initiativeId, survey } = this.props;

    return {
      initiativeId: initiativeId,
      surveyId: survey?._id ?? '',
      scopeLevel: 'survey',
      scopeGroup: '',
      stakeholderType,
    };
  };

  sendApiRequest = async ({ action, userRole, userId, email }: ApiUrls): Promise<any> => {
    const { survey } = this.props;

    const method = action === Action.Remove ? 'delete' : 'patch';

    if (!survey?._id) {
      throw Error('Invalid request');
    }

    // Decide which endpoint to hit
    const url = email ?
      `surveys/${survey._id}/users/${userRole}/${email}/onboard` :
      `surveys/${survey._id}/users/${userRole}/${userId}`;

    return G17Client.request({
      url,
      data: { userId, email },
      method: method,
    }).then((r) => r.data.data);
  };

  handleAddUser = ({ userId, email }: { userId?: string; email?: string }) => {
    const { activeUserRole } = this.state;

    if (!activeUserRole) {
      return;
    }

    this.setState(
      {
        searchModal: false, // Close Search
        loading: true,
        message: undefined,
      },
      async () => {
        try {
          const data = await this.sendApiRequest({
            action: Action.Add,
            userRole: activeUserRole,
            userId,
            email,
          });

          analytics.track(AnalyticsEvents.SurveyDelegationAdded, this.getAnalyticsPayload(activeUserRole));
          if (data?._id) {
            analytics.track(AnalyticsEvents.UserInvited, {
              initiativeId: this.props.initiativeId,
              onboardId: data._id,
            });
          }

          await this.reloadUsers();
          this.props.handleSubmit?.();
        } catch (e) {
          this.handleError(e);
        }
      }
    );
  };

  handleRemoveUser = (userRole: SurveyUserRoles, userId?: string, email?: string) => {
    this.setState(
      {
        loading: true,
        message: undefined,
      },
      async () => {
        try {
          await this.sendApiRequest({
            action: Action.Remove,
            userRole,
            userId,
            email,
          });
          analytics.track(AnalyticsEvents.SurveyDelegationRemoved, this.getAnalyticsPayload(userRole));

          await this.reloadUsers();
          this.props.handleSubmit?.();
        } catch (e) {
          this.handleError(e);
        }
      }
    );
  };

  openSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, userRole: SurveyUserRoles, title: string) => {
    e.preventDefault();
    const ignoredIds = this.state.users.filter((u) => !!u._id && u.roles?.includes(userRole)).map((u) => u._id);
    this.setState({ searchModal: true, searchModalTitle: title, activeUserRole: userRole, ignoredIds });
  };

  closeSearch = () => {
    this.setState({ searchModal: false });
  };

  render() {
    const { isOpen, survey, headerTitle } = this.props;

    if (!survey) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} toggle={this.props.toggle} backdrop='static' className='delegate-form' size='md'>
        <ModalHeader toggle={this.props.toggle}>{headerTitle}</ModalHeader>
        <ModalBody>{this.getModalBody()}</ModalBody>
      </Modal>
    );
  }

  getModalBody() {
    const { title, description, survey, surveyRole } = this.props;
    const { ignoredIds, loading, error, errorMessage } = this.state;

    if (error) {
      return <h2>{errorMessage}</h2>;
    }

    return (
      <>
        <h2>{title ?? 'Delegation'}</h2>
        <p>
          {description ??
            'This is a list of all the users currently assigned as contributors or verifiers for this data.'}
        </p>
        <div className='row user-lists mt-3'>
          {this.getUserTable(surveyRole)}
        </div>
        {loading && (
          <div className='row'>
            <div className='col py-5'>
              <Loader />
            </div>
          </div>
        )}
        <SurveyDelegationSearchModal
          isOpen={this.state.searchModal}
          toggle={this.closeSearch}
          title={this.state.searchModalTitle}
          surveyId={survey?._id}
          handleSubmit={this.handleAddUser}
          ignoredIds={ignoredIds}
        />
      </>
    );
  }

  getUserTable(userRole: SurveyUserRoles) {
    const { users } = this.state;

    return (
      <div key={`delegation-modal-${userRole}`} className='col'>
        {this.renderUserList(
          userRole,
          users.filter((u) => !!u.roles?.includes(userRole))
        )}
        {this.renderAddBtn(userRole)}
      </div>
    );
  }

  renderAddBtn(userRole: SurveyUserRoles) {
    if (this.state.loading) {
      return '';
    }
    if (!this.hasUserRolePermission(userRole)) {
      return <></>;
    }

    const title = `Add a ${roles[userRole].shortName}`;

    return (
      <div className='p-1 text-right'>
        <Button color='secondary' onClick={(e) => this.openSearch(e, userRole, title)}>
          {title}
        </Button>
      </div>
    );
  }

  getUserRow(userRole: SurveyUserRoles, user: User) {
    return (
      <>
        <Button color='link' className='mx-1 p-0' onClick={() => this.handleRemoveUser(userRole, user._id, user.email)}>
          <i className='fas fa-times text-ThemeDangerMedium' />
        </Button>
        {this.getUserText(user)}
      </>
    );
  }

  getUserText(user: User) {
    if (user.email) {
      return (
        <span className={'pendingText'}>
          <i title='Invitation sent and waiting confirmation' className='fas fa-paper-plane pending mr-1' />
          {ellipsis(user.firstName, 45)} (pending)
        </span>
      );
    }

    if (user.active) {
      return (
        <span>
          {user.firstName} {user.surname}{' '}
        </span>
      );
    }

    return (
      <span className={'user-inactive'}>
        {user.firstName} {user.surname}{' '}
        <SimpleTooltip
          id={`optionTooltip-${user._id}`}
          text={'User is not active'}
          component={<i className='fas fa-info-circle' />}
        />
      </span>
    );
  }

  canContribute = () => {
    const { user, survey } = this.props;
    if (!user) {
      return false;
    }
    return SurveyPermissions.canContribute(survey, user);
  };

  canVerify = () => {
    const { user, survey } = this.props;
    if (!user) {
      return false;
    }
    return SurveyPermissions.canVerify(survey, user);
  };

  canManage = () => {
    const { user, survey } = this.props;
    if (!user) {
      return false;
    }
    return SurveyPermissions.canManage(survey, user);
  };

  hasUserRolePermission(userRole: string) {
    const canManage = this.canManage();
    if (canManage) {
      return true;
    }

    if (userRole === SurveyUserRoles.Stakeholder) {
      return this.canContribute();
    }

    if (userRole === SurveyUserRoles.Verifier) {
      return this.canVerify();
    }

    return this.props.isManager;
  }

  renderUserList(userRole: SurveyUserRoles, users: User[]) {
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div className='user-list m-1'>
        <Table
          data={users}
          columns={[
            {
              accessorKey: 'name',
              header: `${roles[userRole].shortName}s`,
              sortingFn: (a, b) => naturalSort(getFullName(a.original), getFullName(b.original)),
              cell: ({ row }) => this.getUserRow(userRole, row.original),
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: DelegationModalProps) => {
  const { initiativeId } = ownProps;
  return {
    user: getCurrentUser(state),
    isManager: isUserManagerByInitiativeId(state, initiativeId),
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyDelegationModal);
